<template>
  <c-flex my="4" justify="space-between" align="center">
    <c-flex align="center">
      <img
        v-if="company.logo"
        alt="user-avatar"
        class="user-avatar"
        :src="company.logo"
      />
      <img v-else alt="user-avatar" width="45px" src="@/assets/img/user.jpg" />
      <c-box
        ml="4"
        color="gray.700"
        style="cursor: pointer"
        @click="goToProfile(company.id)"
      >
        <c-text flex="column" fontSize="14px" fontWeight="700">{{
          company.name
        }}</c-text>

        <c-text fontSize="13px" fontWeight="400">{{ company.country }}</c-text>
      </c-box>
    </c-flex>

    <c-flex justify="end">
      <c-button
        variant-color="vc-orange"
        variant="outline"
        size="xs"
        border="1px"
        :is-loading="btnLoading"
        @click="follow"
      >
        {{ followed ? 'Following' : 'Follow' }}
      </c-button>
    </c-flex>
  </c-flex>
</template>

<script>
import { followCompany } from '@/services/company.js';

export default {
  name: 'CompanyCard',
  props: ['company'],
  data() {
    return {
      btnLoading: false,
      followed: false,
    };
  },
  methods: {
    async follow() {
      this.btnLoading = true;
      const companyId = this.company.id;

      try {
        await followCompany(companyId);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
      } catch (e) {
        this.btnLoading = false;
        console.log(e);
      }
    },
    goToProfile(companyId) {
      this.$router.push({ name: 'CompanyProfile', params: { companyId } });
    },
  },
};
</script>

<style scoped>
button:hover {
  background-color: #e5724e;
  color: #fff;
}
.user-avatar {
  border-radius: 50%;
  width: 45px; /* or a fixed width */
  height: 45px; /* or a fixed height */
  object-fit: cover;
}
</style>
