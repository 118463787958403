<template>
  <c-flex my="4" justify="space-between" align="center">
    <c-flex align="center">
      <img
        v-if="person.profilePhoto"
        alt="user-avatar"
        class="user-avatar"
        :src="person.profilePhoto"
      />
      <img v-else alt="user-avatar" width="45px" src="@/assets/img/user.jpg" />
      <c-box
        ml="4"
        color="gray.700"
        style="cursor: pointer"
        @click="goToProfile(person.id)"
      >
        <c-text flex="column" fontSize="14px" fontWeight="700"
          >{{ person.firstname }} {{ person.lastname }}</c-text
        >

        <c-text v-if="person.companies.length > 0" fontSize="13px" fontWeight="400">{{
          person.companies[0].name
        }}</c-text>
      </c-box>
    </c-flex>

    <c-flex justify="end">
      <c-button
        variant-color="vc-orange"
        variant="outline"
        size="xs"
        border="1px"
        :is-loading="btnLoading"
        @click="follow"
      >
        {{ followed ? 'Following' : 'Follow' }}
      </c-button>
    </c-flex>
  </c-flex>
</template>

<script>
import { followUser } from '@/services/user';

export default {
  name: 'PeopleCard',
  props: ['person'],
  data() {
    return {
      btnLoading: false,
      followed: false,
    };
  },
  methods: {
    async follow() {
      this.btnLoading = true;
      const userId = this.person.id;

      try {
        await followUser(userId);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
      } catch (e) {
        this.btnLoading = false;
        console.log(e);
      }
    },
    goToProfile(userId) {
      console.log(userId);
      this.$router.push({ name: 'Profile', params: { userId } });
    },
  },
};
</script>

<style scoped>
button:hover {
  background-color: #e5724e;
  color: #fff;
}
.user-avatar {
  border-radius: 50%;
  width: 45px; /* or a fixed width */
  height: 45px; /* or a fixed height */
  object-fit: cover;
}
</style>
