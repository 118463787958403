<template>
  <c-flex my="4" justify="space-between" align="center">
    <c-flex>
      <img
        :src="
          event.thumb_nail ??
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU'
        "
        alt="event_thumbnail"
        class="event-image"
      />
      <c-box ml="4" color="gray.700">
        <c-text flex="column" fontSize="11px" fontWeight="500">{{
          event.start_date
        }}</c-text>
        <c-text
          class="event-title"
          flex="column"
          fontSize="13px"
          fontWeight="800"
          >{{ event.description }}</c-text
        >
        <c-text fontSize="11px" mt="1" fontWeight="700">{{
          event.name
        }}</c-text>
        <!-- <c-flex mr="3" justify="start">
          <c-button variant-color="vc-orange" variant="link"> Attend </c-button>
        </c-flex> -->
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: 'EventCard',
  props: ['event'],
};
</script>

<style scoped>
.event-image {
  width: 139px;
  border-radius: 0.5rem;
  background-size: cover;
}

.event-title {
  /* white-space: nowrap; */
  overflow: hidden;
  max-width: 190px;
  text-overflow: ellipsis;
  max-height: 2.5rem;
}
</style>
