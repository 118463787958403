import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=67d2637b&scoped=true"
import script from "./EventCard.vue?vue&type=script&lang=js"
export * from "./EventCard.vue?vue&type=script&lang=js"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=67d2637b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d2637b",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
